import React, { PureComponent } from "react";
import {
  Button,
  Dropdown,
  Form,
  Header,
  Icon,
  Input,
  Modal,
  Segment,
  Table,
} from "semantic-ui-react";
import FloatButton from "../../shared/FloatButton";
import ekasut from "../../api/ekasut";
import { downloadBlob } from "../../helpers/utils";
import catchNetworkErrors from "../../api/catchNetworkErrors";
import SkeletonPage from "../../shared/SkeletonPage/SkeletonPage";
import auth from "../../helpers/auth";
import storeContext from "../../globalStore/storeContext";
import * as moment from "moment";
import ButtonMU from "@material-ui/core/Button/Button";
import SaveIcon from "@material-ui/icons/Save";
import downloadFile from "./downloadFile";

export class WithdrawnLocomotives extends PureComponent {
  state = {
    activePage: 1,
    modalOpen: false,
    errorOccured: false,
    sectionsResp: [],
    isLoading: false,
    modalLoading: false,
    params: {},
    series: [],
    depos: [],
    repairDepos: [],
    repairTypes: [],
    filterShow: false,
    allTable: [],
    onPageLimit: 100,
  };

  constructor(props) {
    super(props);

    this.filterClear = this.filterClear.bind(this);
    this.filterStateChange = this.filterStateChange.bind(this);
    this.submitFilters = this.submitFilters.bind(this);
    this.changePage = this.changePage.bind(this);
  }

  filterStateChange() {
    this.setState({ filterShow: !this.state.filterShow });
  }

  filterClear() {
    this.setState({
      sectionsResp: this.state.allTable,
      params: {},
      onPageLimit: 100,
    });
  }

  componentDidMount() {
    const me = this;
    let params = {};

    let queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    me.setState({ isLoading: true });

    document
      .querySelector(".content-container")
      .addEventListener("scroll", () => {
        if (
          document.querySelector(".content-container").scrollTop +
            document.querySelector(".content-container").clientHeight >=
          document.querySelector(".content-container").scrollHeight
        ) {
          this.setState({ onPageLimit: this.state.onPageLimit + 100 });
        }
      });

    ekasut.oldReqs
      .removedSick(queryString)
      .then(async function (response) {
        if (response.status !== 200) {
          me.setState({
            isLoading: false,
            errorOccured: true,
            errorCode: response.status,
          });

          return;
        }
        const dataWitchFiles = await Promise.all(
          response.data.map(async (el) => {
            if (el.fileId && Boolean(el.fileId.replace(/[-0]/gi, ""))) {
              return {
                ...el,
                fileName: el.fileName ? el.fileName : "без имени",
                isUrl: true,
              };
            }
            return el;
          })
        );
        me.setState({
          sectionsResp: dataWitchFiles,
          isLoading: false,
          allTable: dataWitchFiles,
        });
      })
      .catch(function (err) {
        if (
          err.response.data["$type"].includes("UnauthorizedAccessException")
        ) {
          auth.logout();
        }

        me.setState({
          isLoading: false,
          errorOccured: true,
          errorCode: err.response.data.message,
        });
      });

    ekasut.locomotivesNsi
      .getSerTps()
      .then(function (response) {
        if (response.status !== 200) {
          return;
        }

        me.setState({ series: response.data });
      })
      .catch(function (err) {
        if (
          err.response.data["$type"].includes("UnauthorizedAccessException")
        ) {
          auth.logout();
        }
        console.log("Error :-S", err);
      });

    ekasut.locomotivesNsi
      .getTypes()
      .then(function (response) {
        if (response.status !== 200) {
          return;
        }

        me.setState({ repairTypes: response.data });
      })
      .catch(function (err) {
        if (
          err.response.data["$type"].includes("UnauthorizedAccessException")
        ) {
          auth.logout();
        }
        console.log("Error :-S", err);
      });

    ekasut.ekasutData
      .getCtTree()
      .then(function (response) {
        if (response.status !== 200) {
          return;
        }

        me.setState({
          depos: response.data.map((pred) => ({
            value: pred.value,
            key: pred.key,
            text: pred.text,
            className:
              pred.level === 1 ? "root-pred" : "child-pred-" + (pred.level - 1),
          })),
        });
      })
      .catch(function (err) {
        if (
          err.response.data["$type"].includes("UnauthorizedAccessException")
        ) {
          auth.logout();
        }
        console.log("Error :-S", err);
      });

    ekasut.ekasutData
      .getServiceTree()
      .then(function (response) {
        if (response.status !== 200) {
          return;
        }

        me.setState({
          repairDepos: response.data.map((pred) => ({
            value: pred.value,
            key: pred.key,
            text: pred.text,
            className:
              pred.level === 0 ? "root-pred" : "child-pred-" + pred.level,
          })),
        });
      })
      .catch(function (err) {
        if (
          err.response.data["$type"].includes("UnauthorizedAccessException")
        ) {
          auth.logout();
        }
        console.log("Error :-S", err);
      });
  }

  handleClose = () => this.setState({ modalOpen: false });

  submitFilters() {
    const me = this;
    const { params } = this.state;

    let queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    me.setState({ isLoading: true });

    ekasut.oldReqs
      .removedSick(queryString)
      .then(async function (response) {
        if (response.status !== 200) {
          return;
        }
        const dataWitchFiles = await Promise.all(
          response.data.map(async (el) => {
            if (el.fileId && Boolean(el.fileId.replace(/[-0]/gi, ""))) {
              return {
                ...el,
                fileName: el.fileName ? el.fileName : "без имени",
                isUrl: true,
              };
            }
            return el;
          })
        );
        me.setState({
          sectionsResp: dataWitchFiles,
          isLoading: false,
          allTable: dataWitchFiles,
        });
      })
      .catch(function (err) {
        if (
          err.response.data["$type"].includes("UnauthorizedAccessException")
        ) {
          auth.logout();
        }
        me.setState({
          isLoading: false,
          errorOccured: true,
          errorCode: err.response.data.message,
        });
      });
  }

  changePage(event, data) {
    this.setState({ activePage: data.activePage });
  }

  closeErrorModal = () => this.setState({ errorOccured: false });

  static contextType = storeContext;

  render() {
    const me = this,
      lis = [];

    if (this.state.sectionsResp.length > 0) {
      let target = this.state.sectionsResp.slice(
        (this.state.activePage - 1) * this.state.onPageLimit,
        this.state.activePage * this.state.onPageLimit
      );

      target.forEach(function (item, index) {
        lis.push(
          <Table.Row key={index}>
            <Table.Cell className="center aligned">
              {(me.state.activePage - 1) * me.state.onPageLimit + (index + 1)}
            </Table.Cell>
            <Table.Cell className="center aligned">{item.serName}</Table.Cell>
            <Table.Cell className="center aligned">
              {item.locNum}
              {item.prsCharLoc}
            </Table.Cell>
            <Table.Cell className="center aligned">
              {item.depoPripName}
            </Table.Cell>
            <Table.Cell className="center aligned">
              {item.dateRepairFinish
                ? moment(item.dateRepairFinish).format("DD.MM.YYYY HH:mm")
                : null}
            </Table.Cell>
            <Table.Cell className="center aligned">
              {item.repairKindName}
            </Table.Cell>
            <Table.Cell className="center aligned">
              {item.hasKo ? (
                <Icon size="large" name="check circle outline" />
              ) : null}
            </Table.Cell>
            <Table.Cell className="center aligned">
              {item.neplanCount}
            </Table.Cell>
            <Table.Cell className="center aligned">
              {item.kasantCount}
            </Table.Cell>
            <Table.Cell className="center aligned">
              {item.kind1Count}
            </Table.Cell>
            <Table.Cell className="center aligned">
              {item.dateRemoved
                ? moment(item.dateRemoved).format("DD.MM.YYYY HH:mm")
                : null}
            </Table.Cell>
            <Table.Cell className="center aligned">
              {item.isUrl ? (
                <ButtonMU
                  key={item.fileId}
                  style={{ textTransform: "none", marginRight: 10 }}
                  color="primary"
                  size="small"
                  onClick={() => downloadFile(item.fileId, item.fileName)}
                  // download={item.fileName}
                  href={item.url}
                  target={"_blank"}
                  startIcon={<SaveIcon />}
                >
                  {item.fileName}
                </ButtonMU>
              ) : null}
            </Table.Cell>
          </Table.Row>
        );
      });
    }

    const { params } = this.state;

    return (
      <div>
        {me.state.filterShow ? (
          <Modal
            closeIcon
            size="mini"
            open={this.state.filterShow}
            onClose={me.filterStateChange}
          >
            <Modal.Content>
              <Modal.Description>
                <Form
                  onSubmit={() => {
                    this.submitFilters();
                    me.filterStateChange();
                  }}
                  onKeyPress={(e) => {
                    if (e.key == "Enter") {
                      this.submitFilters();
                      me.filterStateChange();
                    }
                  }}
                >
                  <Form.Field>
                    <label>№ локомотива</label>
                    <Input
                      placeholder="№ локомотива"
                      value={this.state.params.num ? this.state.params.num : ""}
                      onChange={(e, { value }) => {
                        var num = parseInt(value, 10);
                        if (num) {
                          this.setState({ params: { ...params, num: num } });
                        } else {
                          delete params.num;
                          this.setState({ params: { ...params } });
                        }
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Серия</label>
                    <Dropdown
                      placeholder="Серия"
                      fluid
                      multiple
                      search
                      selection
                      options={this.state.series}
                      value={this.state.params.ser ? this.state.params.ser : []}
                      onChange={(e, { value }) => {
                        this.setState({ params: { ...params, ser: value } });
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Депо приписки</label>
                    <Dropdown
                      placeholder="Депо приписки"
                      fluid
                      multiple
                      search
                      selection
                      options={this.state.depos}
                      value={
                        this.state.params.pripDepo
                          ? this.state.params.pripDepo
                          : []
                      }
                      onChange={(e, { value }) => {
                        this.setState({
                          params: { ...params, pripDepo: value },
                        });
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>НР более</label>
                    <Input
                      placeholder="НР более"
                      value={params.nrMore}
                      value={
                        this.state.params.nrMore ? this.state.params.nrMore : ""
                      }
                      onChange={(e, { value }) => {
                        let nrMore = parseInt(value, 10);
                        if (
                          nrMore !== null &&
                          nrMore !== undefined &&
                          !isNaN(nrMore)
                        ) {
                          this.setState({
                            params: { ...params, nrMore: nrMore },
                          });
                        } else {
                          delete params.nrMore;
                          this.setState({ params: { ...params } });
                        }
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>отказов более</label>
                    <Input
                      placeholder="отказов более"
                      value={params.kasantMore}
                      value={
                        this.state.params.kasantMore
                          ? this.state.params.kasantMore
                          : ""
                      }
                      onChange={(e, { value }) => {
                        let kasantMore = parseInt(value, 10);
                        if (
                          kasantMore !== null &&
                          kasantMore !== undefined &&
                          !isNaN(kasantMore)
                        ) {
                          this.setState({
                            params: { ...params, kasantMore: kasantMore },
                          });
                        } else {
                          delete params.kasantMore;
                          this.setState({ params: { ...params } });
                        }
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>отказов 1кат более</label>
                    <Input
                      placeholder="отказов 1кат более"
                      value={params.kasant1More}
                      value={
                        this.state.params.kasant1More
                          ? this.state.params.kasant1More
                          : ""
                      }
                      onChange={(e, { value }) => {
                        let kasant1More = parseInt(value, 10);
                        if (
                          kasant1More !== null &&
                          kasant1More !== undefined &&
                          !isNaN(kasant1More)
                        ) {
                          this.setState({
                            params: { ...params, kasant1More: kasant1More },
                          });
                        } else {
                          delete params.kasant1More;
                          this.setState({ params: { ...params } });
                        }
                      }}
                    />
                  </Form.Field>
                  <div
                    style={{
                      marginBottom: 10,
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <Button className="button-link" onClick={me.filterClear}>
                      Сброс
                    </Button>
                    <Button
                      className="button-primary"
                      style={{ marginLeft: 15 }}
                      // onClick={() => {
                      //   this.submitFilters();
                      //   me.filterStateChange();
                      // }}
                    >
                      Применить
                    </Button>
                  </div>
                </Form>
              </Modal.Description>{" "}
            </Modal.Content>
          </Modal>
        ) : (
          <div>
            {!me.state.isLoading ? (
              <FloatButton
                main={{
                  text: "",
                  rotate: true,
                  icon: "angle left",
                }}
                actions={[
                  // {
                  //     text: "Excel",
                  //     icon: "file excel outline",
                  //     onClick: () => {
                  //         this.context.prompt.show("Загрузка отчета...", "", {loading: true})
                  //         const params = new URLSearchParams(
                  //             Object.keys(this.state.params).map((key) => [key, this.state.params[key]])
                  //         )
                  //
                  //         ekasut.ekasutData.getSickExcel(params)
                  //             .then((res) => {
                  //                 downloadBlob("locomotives_report.xlsx", res.data)
                  //             })
                  //             .catch(catchNetworkErrors)
                  //             .finally(() => {
                  //                 this.context.prompt.close()
                  //             })
                  //     }
                  // },
                  {
                    text: "Настройка фильтров",
                    icon: "filter",
                    onClick: () => {
                      me.filterStateChange();
                    },
                  },
                ]}
              />
            ) : null}
          </div>
        )}

        <Segment
          basic
          className={`report-container ${
            this.state.isLoading ? "loading-report" : ""
          }`}
        >
          {this.state.modalLoading ? (
            <SkeletonPage view={"ModalGroupRisk"} />
          ) : null}

          {this.state.isLoading ? (
            <SkeletonPage view={"GroupRisk"} />
          ) : (
            <Table celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell className="center aligned" colSpan="13">
                    Секции группы риска
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell className="center aligned">
                    №
                  </Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">
                    Серия
                  </Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">
                    Секция
                  </Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">
                    Приписка
                  </Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">
                    Дата окончания планового ремонта
                  </Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">
                    Вид планового ремонта
                  </Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">
                    Признак проведения КО
                  </Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">
                    Количество НР
                  </Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">
                    Количество отказов
                  </Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">
                    Количество отказов первой категории
                  </Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">
                    Дата вывода из группы риска
                  </Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">
                    Прикрепленные файлы
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {lis.length > 0 ? (
                  lis
                ) : (
                  <Table.Row>
                    <Table.Cell className="center aligned" colSpan="11">
                      Нет элементов удовлетворяющих условиям поиска
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          )}

          <Modal
            open={this.state.errorOccured}
            closeOnDimmerClick={false}
            basic
            size="small"
          >
            <Header
              icon="warning sign"
              content={`Ошибка ${this.state.errorCode}`}
            />
            {/*<Modal.Content></Modal.Content>*/}
            <Modal.Actions>
              <Button onClick={this.closeErrorModal} inverted>
                <Icon name="checkmark" /> Закрыть
              </Button>
            </Modal.Actions>
          </Modal>
        </Segment>
      </div>
    );
  }
}
