import React, { useCallback, useState } from "react";
import style from "./LocoDetails.module.scss";
import { HeaderLocoDetailsNew } from "./HeaderLocoDetails/HeaderLocoDetailsNew";
import { LocoDetailsTabsNew } from "./Tabs/LocoDetailsTabsNew";
import { useGetOnBoardDiagnostics } from "./hook/useGetLocoDetailsBySection";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
// test

export const LocoDetails = ({ info, setAddProt }) => {
  const { data, loading } = useGetOnBoardDiagnostics({ info });
  const [trainInfo, setTrainInfo] = useState(0);

  const antIcon = <LoadingOutlined style={{ fontSize: 54 }} spin />;

  const changeActiveItem = useCallback((index) => {
    setTrainInfo(index);
  }, []);

  return (
    <div className={style.wrap}>
      {loading && (
        <div className={style.spin}>
          <Spin indicator={antIcon} spinning={loading} />
        </div>
      )}

      {!loading && (
        <>
          <HeaderLocoDetailsNew
            info={data}
            setAddProt={setAddProt}
            changeActiveItem={changeActiveItem}
            trainInfo={trainInfo}
          />
          <div className={style.body}>
            <LocoDetailsTabsNew info={data[trainInfo]} />
          </div>
        </>
      )}
    </div>
  );
};
