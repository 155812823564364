import * as React from "react";

import {
  Container,
  Box,
  CssBaseline,
  Button,
  createTheme,
  ThemeProvider,
} from "@material-ui/core";

import authLogo from "../../assets/images/main/nav-ekasut.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Auth() {
  const handleSubmit = () => {
    window.location = `${window.backendUrl}/Auth/Login?fromUrl=${window.location.href}`;
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: "30vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={authLogo} />
          <Button
            style={{
              marginTop: 40,
            }}
            variant="contained"
            onClick={handleSubmit}
          >
            Вход
          </Button>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
