import React, { useEffect } from "react";
import { Message, Tab } from "semantic-ui-react";
import LocPicture from "./LocPicture";
import BaseInfo from "./BaseInfo";
import StyledAccordion from "./StyledAccordion";
import AboutMileage from "./AboutMileage";
import StateHistory from "./StateHistory";
import { momentIfStr } from "../../helpers/utils";
import OnBoardDiagnostic from "./OnBoardDiagnostic";
import { observer, Observer } from "mobx-react-lite";
import FuelEnergyResources from "./FuelEnergyResources";
import ElectronicPassport from "./ElectronicPassport";
import TriggerBarrierFunctions from "./TriggerBarrierFunctions";
import { useStore } from "./StoreProvider";
import RouteList from "./RouteList";
import catchNetworkErrors from "../../api/catchNetworkErrors";
import MovementHistory from "./MovementHistory";
import cn from "classnames";
import { HeaderLocoDetails } from "../../views/CommissionInspection/Inspection/LocoDetails/HeaderLocoDetails/HeaderLocoDetails";
import FutureDropDown from "./FutureDropDown";
import ekasut from "../../api/ekasut";

const SectionsTab = observer(
  ({
    onLoaded,
    onStartLoad,
    tabNum,
    serLoc,
    znsLoc,
    prsLoc,
    className,
    info,
    setAddProt,
    currentObjId,
  }) => {
    const s = useStore();

    useEffect(() => {
      onStartLoad();
      s.fetchDetailsIfNeed()
        .then(onLoaded)
        .catch((err) => {
          onLoaded();
          return catchNetworkErrors(err);
        });
    }, [s, s.locId, s.seriesId, s.number, s.sectionId, onLoaded, onStartLoad]);

    if (!s.detailsLoaded) {
      return null;
    }

    if (s.tabs.size === 0) {
      return <Message content="Локомотив не найден" warning />;
    }
    return (
      <>
        <Tab
          className={className}
          panes={s.details.map((detail) => ({
            menuItem: detail.nameSec,
            render: () => (
              // I need to make this part of code observable
              // because i put jsx inside function and it can be seen as separate component
              <Observer>
                {() => {
                  const tab = s.tabs.get(detail.idObject);
                  return (
                    <Tab.Pane key={detail.idObject}>
                      <div className="tab-content">
                        <div className="general-info">
                          <div className="picture-container">
                            <LocPicture detail={detail} />
                          </div>
                          <BaseInfo className="base-info" detail={detail} />
                        </div>
                        {/*<Message negative />*/}
                        <HeaderLocoDetails
                          info={info}
                          setAddProt={setAddProt}
                          currentObjId={currentObjId}
                        />
                        <StyledAccordion
                          className="accordion"
                          activeIndex={tab.activePanelIndex}
                          onPanelClick={(index) =>
                            (tab.activePanelIndex = index)
                          }
                          panels={[
                            {
                              title: "Сведения о пробегах секции",
                              content: <AboutMileage detail={detail} />,
                            },
                            {
                              title: "История состояний",
                              content: (
                                <StateHistory
                                  detail={detail}
                                  lastDateState={momentIfStr(detail.dateOp)}
                                />
                              ),
                            },
                            {
                              title: "История перемещений",
                              content: <MovementHistory detail={detail} />,
                            },
                            {
                              title: "Бортовая диагностика",
                              content: <OnBoardDiagnostic tab={tab} />,
                              loading: tab.onBoardDiagnosticLoading,
                              onOpen: () => {
                                tab
                                  .fetchOnBoardDiagnosticIfNeed(
                                    detail.serLoc,
                                    detail.znsLoc,
                                    detail.prsLoc
                                  )
                                  .catch(catchNetworkErrors);
                              },
                            },
                            {
                              title: "Маршрутные листы",
                              content: (
                                <RouteList
                                  tab={tab}
                                  serLoc={detail.serLoc}
                                  znsLoc={detail.znsLoc}
                                  prsLoc={detail.prsLoc}
                                />
                              ),
                              loading: tab.routeListLoading,
                              onOpen: () => {
                                tab
                                  .fetchRouteListIfNeed(
                                    detail.serLoc,
                                    detail.znsLoc,
                                    detail.prsLoc
                                  )
                                  .catch(catchNetworkErrors);
                              },
                            },
                            {
                              title: "Топливно-энергетические ресурсы",
                              content: (
                                <FuelEnergyResources
                                  detail={detail}
                                  tab={tab}
                                />
                              ),
                              loading: tab.fuelEnergyResLoading,
                              onOpen: () => {
                                tab
                                  .fetchFuelEnergyResIfNeed([
                                    {
                                      series: detail.serLoc,
                                      num: detail.znsLoc,
                                      section: detail.prsLoc,
                                    },
                                  ])
                                  .catch(catchNetworkErrors);
                              },
                            },
                            {
                              title: "Электронный паспорт",
                              content: <ElectronicPassport tab={tab} />,
                              loading: tab.electricPassportLoading,
                              onOpen: () => {
                                tab
                                  .fetchElectricPassportIfNeed(detail.idObject)
                                  .catch(catchNetworkErrors);
                              },
                            },
                            {
                              title: "Срабатывания барьерных функций",
                              content: <TriggerBarrierFunctions tab={tab} />,
                              loading: tab.barrierFunctionsLoading,
                              onOpen: () => {
                                tab
                                  .fetchBarrierFunctionsIfNeed(detail.idObject)
                                  .catch(catchNetworkErrors);
                              },
                            },
                            // locType - serLoc
                            // locNum - znsLoc
                            // locSec - prsLoc
                            {
                              title: "Сведения о текущем состоянии ВСЦ",
                              content: (
                                <FutureDropDown tab={tab} detail={detail} />
                              ),
                              loading: tab.barrierFunctionsLoading,
                              onOpen: () => {
                                console.log(detail);
                                tab
                                  .fetchBarrierFunctionsIfNeed(detail.idObject)
                                  .catch(catchNetworkErrors);
                              },
                            },
                          ]}
                        />
                      </div>
                    </Tab.Pane>
                  );
                }}
              </Observer>
            ),
          }))}
        />
      </>
    );
  }
);

export default SectionsTab;
